import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";


import Hero from "components/hero/TwoColumnWithFeaturesAndTestimonial.js";
import GetStarted from "components/cta/GetStartedLight.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import Features from "components/features/VerticalWithAlternateImageAndText.js";


export default () => {
  return (

    <AnimationRevealPage>
      <Hero />
      <Features />
      <GetStarted />
      <Footer />
    </AnimationRevealPage>
  );
}
